<template>
    <AppContainer>
        <Messages/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import Messages from '@/components/dashboard/messages/Messages.vue'
export default {
  name: 'MessagesList',
  components: {
    AppContainer,
    Messages
  }
}
</script>