<template>
<div>
    <!-- leaddetails-left -->
    <div class="leaddetails-left">
        <div class="vehicle-interest border-bottom">
            <div class="detais-search">
                <div class="form-group Vehicle">
                    <input type="search" class="form-control" placeholder="Search User" />
                    <img class="img-fluid Vehi-img" src="@/assets/images/icons/search.svg" alt="" />
                    <!-- <div class="vdot dropbtn dropdown" v-on:click="myFunction()" >
                        <div id="myDropdown" class="dropdown-content">
                            <ul >
                                <a href="#">All</a>
                                <a href="#">Active</a>
                                <a href="#">Expire</a>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="card rounded-3">
            <div class="card-body mt-0">
                <div class="media border-bottom msg-person" v-for="(conv,index) in conversations" :key="index">
                    <!-- :class="activeConv.booking ? conv.booking ? conv.booking.tracking_id == activeConv.booking.tracking_id ? 'active':'' : conv.req_a_quote.tracking_id == activeConv.booking.tracking_id ? 'active':'' :''" -->
                    <!-- {{activeConv}} -->
                    <!-- 2. {{conv}} -->
                    <a href="#!" class="p-2 d-flex" :class="Object.keys(activeConv).length > 0 ? conv.booking ? conv.booking.tracking_id == activeConv.booking.tracking_id ? 'active':'' : conv.req_a_quote.tracking_id == activeConv.req_a_quote.tracking_id ? 'active':'' :''" v-on:click.prevent="getMessages(conv)">
                        <div class="mr-3">
                            <img v-if="conv.user.picture" :src="conv.user.picture" class="img-fluid" alt="Profile Picture"/>
                            <img v-else class="img-fluid" src="@/assets/images/profile/profile.svg" alt="Profile Picture">
                            <span class=" green-con" id="user-active-1" ></span>
                        </div>
                        <div class="media-body">
                            <h5 class="mb-0">{{conv.user.name}}</h5>
                            <p class="mb-0 text-dark" v-if="conv.booking">Order ID: #{{conv.booking.tracking_id}}</p>
                            <p class="mb-0 text-dark" v-if="conv.req_a_quote">Order ID: #{{conv.req_a_quote.tracking_id}}</p>
                        </div>
                    </a>
                </div>
                <button data-bs-toggle="modal" data-bs-target="#exampleModal" class="ond-btn-p mx-auto my-3" v-if="conversations.length == 0"> Start new conversation</button>
            </div>
        </div>
    </div>
    <!-- leaddetails-left -->

    <!-- leaddetails-mid -->
    <div class="leaddetails-mid m-0">
        <div class="activity-area m-0">
            <div class="card rounded-3" v-if="Object.keys(activeConv).length > 0">
                <div class="card-header border-bottom">
                    <div class="media p-2 border-bottom border-light">
                        <div class="d-flex mr-3">
                            <img v-if="activeConv.user.picture" class="media-object" :src="activeConv.user.picture" alt="" style="width: 40px">
                            <img v-else class="media-object" src="@/assets/images/profile/profile.svg" alt="" style="width: 40px">
                        </div>
                        <div class="media-body msg-person">
                            <h5 class="mb-0 text-dark">{{activeConv.user.name}}</h5>
                            <p class="mb-0" v-if="activeConv.user.city">{{activeConv.user.city}}, {{activeConv.user.state}}</p>
                        </div>

                        <a href="#" class="btn-start-new-chat float-right" data-bs-toggle="modal" data-bs-target="#exampleModal"><img class="img-fluid" src="@/assets/images/icons/add.svg" alt=""></a>
                    </div>
                </div>
                <div class="card-body mt-0">
                    <div class="message-content border-0" id="messagesSection">
                        <div class="message-contentss chatbot">
                            <div class="activity border-top-0" v-for="(msg,index) in messages" :key="index">
                                <div class="activity-icon" v-if="msg.sender_type == 2">
                                    <img v-if="msg.user.picture" class="img-fluid rounded-circle" :src="msg.user.picture" alt="" />
                                    <img v-else class="img-fluid rounded-circle" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" alt="" />
                                </div>
                                <div class="activity-icon" v-if="msg.sender_type == 1">
                                    <img v-if="msg.business.profile_pic" class="img-fluid rounded-circle" :src="msg.business.profile_pic" alt="" />
                                    <img v-else class="img-fluid rounded-circle" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg" alt="" />
                                </div>
                                <div class="activity-text" v-if="msg.sender_type == 2">
                                    <h5>{{msg.user.name}} <span class="msg-date">{{changeDateFormate(msg.created_at,'h:mm a')}}</span></h5>
                                    <p v-html="msg.message"></p>
                                </div>
                                <div class="activity-text" v-if="msg.sender_type == 1">
                                    <h5>{{msg.business.title}} <span class="msg-date">{{changeDateFormate(msg.created_at,'h:mm a')}}</span></h5>
                                    <!-- changeDateFormate(msg.created_at,'MMMM DD, YYYY h:mm a') -->
                                    <p v-html="msg.message"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="message-box">
                        <div id="typing_on" style="color: #717171;"></div>
                        <button class="message-submit" v-on:click="sendMessage" v-on:keyup.enter="sendMessage"><img class="img-fluid" src="@/assets/images/icons/sendmsg.svg" alt=""></button>
                        <div class="input-group">
                            <!-- <input type="text" id="msg_input" class="form-control border chat-input" v-model="msg_.message" placeholder="Type here..."> -->
                            <textarea :disabled="activeConv.status == 2" @keydown.enter="handleEnter" id="msg_input" class="form-control border chat-input" v-model="msg_.message" placeholder="Type here..."></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body mt-0" v-else>
                <div class="text-center p-5">
                    <img src="@/assets/images/messages/no-msg.svg" style="width: 125px">
                    <h3><span v-if="conversations.length > 0">Select a Conversation</span><span v-if="conversations.length == 0">You don’t have any conversation yet</span></h3>
                    <p class="text-muted">These are messages from your clients.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- leaddetails-mid -->

    <!-- leaddetails-right -->
    <div class="leaddetails-right" v-if="Object.keys(activeConv).length > 0">
        <div class="appointments p-0">
            <div class="details-profile">
                <img v-if="activeConv.user.picture" :src="activeConv.user.picture" class="img-fluid" alt="Profile Picture"/>
                <img v-else class="img-fluid" src="@/assets/images/profile/profile.svg" alt="Profile Picture" />
                <h3>{{activeConv.user.name}}</h3>
                <!-- <table class="table mb-2">
                    <tbody>
                        <tr>
                            <td>Email:</td>
                            <td>{{activeConv.user.email}}</td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td>{{activeConv.user.phone}}</td>
                        </tr>
                    </tbody>
                </table> -->
                <div class="row pt-2">
                    <p class="col-md-5 text-left">
                        <span v-if="activeConv.booking_id">Order Detail:</span>
                        <span v-else>Active Offer:</span>
                    </p>
                </div>
                <table class="table">
                    <tbody>
                        <tr>
                            <td><span v-if="activeConv.booking_id">Order</span><span v-else>Offer</span> ID:</td>
                            <td v-if="activeConv.booking_id">#{{activeConv.booking.tracking_id}}</td>
                            <td v-else>#{{activeConv.req_a_quote.tracking_id}}</td>
                        </tr>
                        <tr>
                            <td>Location:</td>
                            <td v-if="activeConv.booking">
                                <span v-if="activeConv.booking.service_rendered == 1">At Business</span>
                                <span v-if="activeConv.booking.service_rendered == 2">Client Preference</span>
                            </td>
                            <td v-else-if="activeConv.req_a_quote">
                                <span v-if="activeConv.req_a_quote.offers[0].render_location == 1">At Business</span>
                                <span v-if="activeConv.req_a_quote.offers[0].render_location == 2">Client Preference</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Price:</td>
                            <td v-if="activeConv.booking">${{activeConv.booking.booking_price}}</td>
                            <td v-else-if="activeConv.req_a_quote">${{activeConv.req_a_quote.offers[0].price}}</td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td v-if="activeConv.booking">{{activeConv.booking.booking_date}} {{activeConv.booking.booking_start_time}}</td>
                            <td v-else-if="activeConv.req_a_quote">{{changeDateFormate(activeConv.req_a_quote.offers[0].date,'MM/DD/YYYY')}}</td>
                        </tr>
                        <tr>
                            <td>Status:</td>
                            <td v-if="activeConv.booking">
                                <span v-if="activeConv.booking.booking_status == 0" class="status status-blue">Pending</span>
                                <span class="status status-green" v-if="activeConv.booking.booking_status == 1">Start</span>
                                <span v-if="activeConv.booking.booking_status == 2" class="status status-red">Cancel</span>
                                <span class="status status-green" v-if="activeConv.booking.booking_status == 3">Complete</span>
                                <span v-if="activeConv.booking.booking_status == 4" class="status status-blue">No-Show</span>
                            </td>
                            <td v-else-if="activeConv.req_a_quote">
                                <span class="status status-green" v-if="activeConv.req_a_quote.offers[0].status == 1">Active</span>
                                <span v-if="activeConv.req_a_quote.offers[0].status == 3" class="status status-red">Canceled</span>
                                <span v-if="activeConv.req_a_quote.offers[0].status == 2" class="status status-red">Rejected</span>
                            </td>
                        </tr>
                        <tr v-if="activeConv.req_a_quote_id && activeConv.req_a_quote.offers[0].status == 1">
                            <td colspan="2">
                                <button class="mr-2" v-on:click="cancelOffer(activeConv.req_a_quote.offers[0].id,activeConv.req_a_quote.offers[0].req_a_quote_id)">Cancel</button>
                                <button style="background:#6e317a">Reject & Make new offer</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="team-tags">
                    <p>Services</p>
                    <ul v-if="activeConv.booking_id">
                        <li v-for="serv in activeConv.booking.boooking_services" :key="serv.id"><a href="#">{{serv.title}}</a></li>
                    </ul>
                    <ul v-else-if="activeConv.req_a_quote_id">
                        <li v-for="serv in activeConv.req_a_quote.services" :key="serv.id"><a href="#">{{serv.title}}</a></li>
                    </ul>
                </div>
                <div class="row border-top pt-2" v-if="activeConv.req_a_quote_id && activeConv.req_a_quote.offers.length > 1">
                    <p class="col-md-12 text-left">Previous Offers:</p>
                </div>
                <table class="table" v-if="activeConv.req_a_quote_id && activeConv.req_a_quote.offers.length > 1">
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Amount</th>
                            <th>From</th>
                            <th>Date</th>
                        </tr>
                        <tr v-for="(offer,index) in activeConv.req_a_quote.offers.filter((info,i) => i > 0)" :key="offer.id">
                            <td>{{index+1}}</td>
                            <td style="text-align:center">${{offer.price}}.00</td>
                            <td>{{offer.offer_from}}</td>
                            <td>{{changeDateFormate(offer.date,'MM/DD/YYYY')}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- leaddetails-right -->

    <!-- <div class="row">
        <div class="col-md-6">
            <div class="card rounded-3">
                <div class="card-header border-bottom">
                    <div class="media p-2 border-bottom border-light">
                        <div class="d-flex mr-3">
                            <a href="#!"><img class="media-object" src="@/assets/images/profile/profile.svg" alt="" style="width: 50px"></a>
                        </div>
                        <div class="media-body">
                            <h5 class="mb-0 text-dark">Zeeshan Safdar</h5>
                            <p class="mb-0 ">Order ID: 1234</p>
                            <p class="mb-0">Services: Full wax, Tatto, Full wax, Tatto, Full wax, Tatto, Full wax, Tatto</p>
                        </div>

                        <a href="#" class="btn-start-new-chat float-right" data-bs-toggle="modal" data-bs-target="#exampleModal"><img class="img-fluid" src="@/assets/images/icons/add.svg" alt=""></a>
                    </div>
                </div>
                <div class="card-body mt-0">
                    <div class="message-content border-0">
                        <div class="message-contentss">
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-4">
                                <div class="replay">
                                    <span class="send-time">8:55 AM, Today</span>
                                    <p>HGreat! What kinds are you planing Could you share with us</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-4">
                                <div class="replay">
                                    <span class="send-time">8:55 AM, Today</span>
                                    <p>HGreat! What kinds are you planing Could you share with us</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-4">
                                <div class="replay">
                                    <span class="send-time">8:55 AM, Today</span>
                                    <p>HGreat! What kinds are you planing Could you share with us</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-4">
                                <div class="replay">
                                    <span class="send-time">8:55 AM, Today</span>
                                    <p>HGreat! What kinds are you planing Could you share with us</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mb-4">
                                <div class="replay">
                                    <span class="send-time">8:55 AM, Today</span>
                                    <p>HGreat! What kinds are you planing Could you share with us</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start mb-4">
                                <div class="sender">
                                    <span class="send-time">8:40 AM, Today</span>
                                    <p>Hi, how are you samim?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="message-box">
                        <div class="input-group">
                            <input type="text" class="form-control border" placeholder="Type here...">
                        </div>
                        <button type="submit" class="message-submit"><img class="img-fluid" src="@/assets/images/icons/sendmsg.svg" alt=""></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 msg-right-side">
            <div class="row">
                <div class="col-12 text-center mb-3">
                    <img src="@/assets/images/profile/profile.svg" alt="">
                    <div class="team-title">
                        <h3>Zeeshan Safdar</h3>
                        <p>Order Id: 123</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="team-tags">
                        <p>Services</p>
                        <ul>
                            <li><a href="#">Full armed wax</a></li>
                            <li><a href="#">Full makeup</a></li>
                            <li><a href="#">Tatto</a></li>
                            <li><a href="#">Hair Cut</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Start New Chat</h5>
                    <a class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img class="img-fluid" src="@/assets/images/icons/Closex.svg" alt=""></span>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="add-member add-trades">
                        <form class="test" method="post">
                            <div class="form-group">
                                <label>Select Order</label>
                                <select class="form-control mt-1" v-model="conv_.booking">
                                    <option value="">Choose order</option>
                                    <option v-for="order in orders" :key="order.id" :value="order">#{{order.tracking_id}} - {{order.user.name}}</option>
                                </select>
                            </div>
                            <!-- <div class="form-group">
                                <label for="inputAddress22">Select Order</label>
                                <Multiselect v-model="conv_.booking_id" :options="options" placeholder='Search Order..' :searchable="true"></Multiselect>
                            </div> -->
                            <div class="form-group">
                                <label for="inputAddress">Message</label>
                                <textarea class="form-control" v-model="conv_.message" rows="3" placeholder="Type here..."></textarea>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#" data-bs-dismiss="modal" aria-label="Cancel" class="add-btn">Cancel</a>
                    <a href="#" v-on:click.prevent="makeConversation" class="rem-btn">Mesage</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    //import Multiselect from '@vueform/multiselect'
    import axios from 'axios';
    import  io  from 'socket.io-client';
    import moment from 'moment'
    import $ from 'jquery';
    var socket = io();
    var user;
    var typing = false;
    var timeout = undefined;

    export default {
        components: {
            //Multiselect
        },
        data() {
            return {
                orders:[],
                conversations:[],
                conv_:{
                    message:'',
                    booking:'',
                    business_id:this.$storage.getStorageSync('business').active_business.id,
                    from:'business'
                },
                activeConv:{},
                messages:[],
                user:{},
                msg_:{
                    message:'',
                    conversation_id:'',
                    msg_from:'business',
                    business_id:this.$storage.getStorageSync('business').active_business.id,
                    receiver_id: ''
                },
                raq_:{
                    offer_id:'',
                    raq_id:'',
                    business_id:this.$storage.getStorageSync('business').active_business.id,
                    from:'business'
                }
                // options: [
                //     'Muhammad Usman',
                //     'Robin',
                //     'Joker',
                // ]
            }
        },
        created(){
            this.getConverstaions();
            this.getOrders();

            if (this.$route.params.id != '' && this.$route.params.id != undefined && this.$route.params.id != null && this.$route.params.id != 'undefined') {
                this.getConversationDetail(this.$route.params.id,this.$route.params.type)
            }

            //alert(this.$route.params.id)
            //alert(this.$route.params.type)

            user = this.$storage.getStorageSync('user');
            socket.on('connect', function() {
                socket.emit('user_connected', user.id);
            });
        },
        methods:{
            getConverstaions(id=''){
                let thiss = this
                axios.get('converstaions/business/'+this.$storage.getStorageSync('business').active_business.id).then(function (response) {
                    if (response.data.status == 'success') {
                        console.log(response.data.data)
                        thiss.conversations = response.data.data
                        if (id != '') {
                            response.data.data.forEach((conv) => {
                                if (conv.id == id) {
                                    //thiss.activeConv = conv;
                                    thiss.getMessages(conv);
                                }
                            });
                        }
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            getOrders(){
                // Get those orders who dont has conversation with this business
                let thiss = this
                axios.get('orders_for_conversation/business/'+this.$storage.getStorageSync('business').active_business.id).then(function (response) {
                    console.log(response.data.data)
                    if (response.data.status == 'success') {
                        thiss.orders = response.data.data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            makeConversation(){
                let thiss = this
                axios.post('create_conversation',this.conv_).then(function (response) {
                    if (response.data.status == 'success') {
                        $('#exampleModal').modal('hide');
                        thiss.getConverstaions(response.data.data)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            getConversationDetail(id,type=''){
                let thiss = this
                axios.get('converstaion_detail/business/'+this.$storage.getStorageSync('business').active_business.id+'/'+id+'/'+type).then(function (response) {
                    if (response.data.status == 'success') {
                        console.log(response.data.data)
                        if (response.data.data.id != undefined && response.data.data.id != '' && response.data.data.id != null && response.data.data.id != 'undefined' ) {
                            //thiss.activeConv = response.data.data;
                            thiss.getMessages(response.data.data)
                        }
                        //thiss.getMessages(thiss.conversations[0]);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            getMessages(conv){
                this.activeConv = conv
                this.msg_.conversation_id = conv.id;
                this.msg_.receiver_id = conv.user.id
                //this.msg_.name = conv.name;
                //this.msg_.receiver_id = conv.user_id;
                let thiss = this
                axios.get('messages/'+conv.id).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.messages = response.data.data
                        setTimeout(function () {
                            thiss.scrollToEnd();
                        }, 1000);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                    }
                });
            },
            handleEnter (e) {
                if (e.ctrlKey) {
                    //alert('ctel enter')
                    let caret = e.target.selectionStart;
                    e.target.setRangeText("\n", caret, caret, "end");
                    this.msg_.message = e.target.value;
                    //this.msg_.message+ "\n";
                }else{
                    e.preventDefault();
                    this.sendMessage();
                }
            },
            sendMessage(){
                if (this.msg_.message != '' && this.msg_.message != undefined && this.msg_.message != null && this.msg_.message != 'undefined') {
                    //console.log(this.activeConv.business,this.msg_,'send')
                    let thiss = this
                    axios.post('send_message',this.msg_).then(function (response) {
                        if (response.data.status == 'success') {
                            //socket.emit("getMessage", thiss.msg_ );
                            thiss.appendMessage(thiss.msg_)
                            thiss.msg_.message = ''
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error.response.data.message);
                        }
                    });
                }
            },
            appendMessage(msg,action='send') {
                let name = this.$storage.getStorageSync('user').name
                let image = this.$storage.getStorageSync('user').picture
                if (image == undefined || image == null || image == '' || image == 'undefined') {
                    image = 'https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg'
                }


                if (action == 'send') {
                    name = this.activeConv.business.title
                    if (this.activeConv.business.profile_pic != undefined && this.activeConv.business.profile_pic != null && this.activeConv.business.profile_pic != '' && this.activeConv.business.profile_pic != 'undefined') {
                        image = this.activeConv.business.profile_pic
                    }else{
                        image = 'https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg'
                    }
                }else if(action == 'receive'){
                    // name = data.name
                    // if (data.profile_pic != undefined && data.profile_pic != null && data.profile_pic != '' && data.profile_pic != 'undefined') {
                    //     image = data.profile_pic
                    // }else{
                    //     image = ''
                    // }
                }

                $(".chatbot").append(
                '<div class="activity border-top-0">'+
                    '<div class="activity-icon">'+
                        '<img class="img-fluid" src="'+image+'" alt="" />'+
                    '</div>'+
                    '<div class="activity-text">'+
                        '<h5>'+name+' <span class="msg-date">'+this.changeDateFormate("","h:mm a")+'</span></h5>'+
                        '<p>'+msg.message+'</p>'+
                    '</div>'+
                '</div>'
                );
                this.scrollToEnd();
            },
            scrollToEnd: function () {
                var container = this.$el.querySelector("#messagesSection");
                container.scrollTop = container.scrollHeight;
            },
            changeDateFormate(date='',formate){
                if (date == '') {
                    return moment().format(formate)
                }
                return moment(date).format(formate)
            },
            cancelOffer(offer_id,raq_id){
                let thiss = this
                this.$swal({
                    title: 'Are you sure?',
                    text: "You want to cancel this Quotetion!",
                    showCancelButton: true,
                    confirmButtonText: 'Yes, cancel it!',
                    cancelButtonText: 'No',
                    reverseButtons: true,
                    customClass: {
                        confirmButton: 'rem-btn',
                        cancelButton: 'add-btn mr-3'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.get('update_raq_status/'+offer_id+'/3/'+raq_id).then(function (response) {
                            if (response.data.status == 'success') {
                                thiss.activeConv.req_a_quote.offers[0].status = 3
                                thiss.activeConv.status = 2
                                thiss.msg_.message = ''
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                thiss.toast.error(error.response.data.message);
                                // if (error.response.status == 422) {
                                //     thiss.toast.error(error.response.data.message);
                                // }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                                //     thiss.$storage.removeStorageSync("user")
                                //     thiss.$storage.removeStorageSync("token")
                                //     thiss.$storage.removeStorageSync("business")
                                //     thiss.$router.push({ name: 'Home'})
                                // }else if (error.response.status == 401) {
                                //     thiss.toast.error(error.response.data.message,{position: "top-right"});
                                // }
                            }
                        });
                    }
                })
            },


            timeoutFunction(){
                typing = false;
                socket.emit('stopped_typing',this.msg_);
            },
            onKeyDownNotEnter(){
                if(typing == false) {
                    typing = true
                    socket.emit('is_typing',  this.msg_);
                    timeout = setTimeout(this.timeoutFunction(), 1000);
                } else {
                    clearTimeout(timeout);
                    timeout = setTimeout(this.timeoutFunction(), 1000);
                }
            }
        },
        mounted() {
            let thiss = this
            socket.on("sendToClient",(data)=>{
                thiss.appendMessage(data);
            });
            socket.on("typing",(data)=>{
                $('#typing_on').html(data.name+" is typing ...");
            });
            socket.on("stopedyping",()=>{
                $('#typing_on').html("");
            });

            socket.on('updateUserStatus', (data) => {
                // let $userStatusIcon = $('.chat-user-online1');
                $.each(data, function (key, val) {
                    if (val !== null && val !== 0) {
                        let $userIcon = $("#user-active-"+key);
                        $userIcon.addClass('user-active');
                    }
                });
            });


            // let $chatInput = $(".chat-input");
            // $chatInput.keypress(function (e) {
            //     alert()
            //     if (e.which === 13 && !e.shiftKey) {
            //         $('#typing_on').html("");
            //         $chatInput.html("");
            //         $( "#msg_input" ).val('');
            //         thiss.sendMessage(thiss.msg_);
            //     }else{
            //         thiss.onKeyDownNotEnter();
            //     }
            // });
        }
    };
</script>
<!-- <style src="@vueform/multiselect/themes/default.css"></style> -->
<style scoped>
    .leaddetails-left,.activity-area,.leaddetails-right{
        min-height: 400px;
    }
    .details-profile{
        padding: 15px;
    }
    .appointments p{
        color:#333;
    }
    .details-profile .table{
        width:100%;
    }
    .status{
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        border-radius: 3px;
        text-decoration: none;
        padding: 4px 10px;
        height: 24px;
        min-width: 64px;
        width: auto;
        text-align: center;
    }
    .status-green{
        color: #19CD9D;
        background: rgba(25, 205, 157, 0.1);
    }
    .status-blue{
        color: #3151A1;
        background: #DBE5FF;
    }
    .status-red{
        color: #EB5757;
        background: rgba(235, 87, 87, 0.1);
    }

    textarea.chat-input {
        resize: none;
        overflow: hidden;
    }

    @media screen and (min-width: 768px) and (max-width: 1050px) {
        .leaddetails-right {
            display: none;
        }
        .leaddetails-left {
            width: 50%;
        }
        .leaddetails-mid {
            width: 40%;
        }
    }
    @media screen and (min-width: 0px) and (max-width: 767px) {
        .leaddetails-right {
            margin-top: 30px;
        }
    }
    @media screen and (max-width: 576px) {
        .leaddetails-left {
            display: none;
        }
    }
</style>


<style type="text/css" >
    .user-active {
        background: #19CD9D;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        display: inline-block;
        /*position: absolute;*/
        top: 4px;
        right: -4px;
    }
    .vehicle-interest, .activity-area{
        padding: 10px;
    }
    .activity-icon{
        margin-right: 2%;
    }
    .activity-icon,.activity{
        display: block;
    }
    .activity-text{
        width: 90%;
    }
    .activity-text h5{
        font-size: 14px;
        font-weight: 800;
        margin-bottom: 2px;
    }
    .activity-text h5 span.msg-date{
        font-size: 12px;
        font-weight: bold;
        color: #939393;
        margin-left: 6px;
    }
    .activity-text p{
        color:#717171;
    }

    /* .details-profile table{
        width: 100% !important;
    } */
    .team-tags{
        border-top: 1px solid #ECEEF1;
        padding: 0;
        margin-top: 10px;
    }
    .btn-start-new-chat{
        background: rgb(140 42 144);
        padding-top: 11px;
        border: 1px solid rgb(140 42 144);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-weight: normal;
        color: rgb(255, 255, 255);
        text-align: center;
        margin-top: 2px;
        margin-right: 4px;
    }
    .btn-start-new-chat img{
        width: 17px;
    }
    .msg-person a{
        width: 100%;
        color: #333;
    }
    .msg-person a:hover, .msg-person a:active,
    .msg-person a.active{
        text-decoration: none;
        background-color: #f9faff;
        color: #8e449d;
    }
    .msg-person a.active{
        border-right: 3px solid #8e449d;
    }
    .msg-person img{
        max-width: 40px;
        border-radius: 50%;
    }
    .msg-person h5{
        font-size: 16px;
        font-weight: 600;
    }
    .msg-person p{
        font-size: 12px;
        font-weight: normal;
    }


    .msg-right-side{
        background-color: #fff;
        padding: 35px 15px;
    }

    .message-content .sender {
        position: relative;
        width: 360px
    }
    .message-content .replay {
        position: relative;
        width: 360px
    }
    .message-content {
        height: 400px;
    }
    .side{
        height: 500px;
        overflow-x: hidden
    }




</style>
